import { useEffect, useState } from "react";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/firebaseConfig";
import SignIn from "../../pages/auth/SignIn";
import SignUp from "../../pages/auth/SignUp";
import Home from "../../pages/Home/Home";
import Header from "../../components/Header/Header";
import Previous from "../../pages/Previous/Previous";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

export enum UserState {
  SIGN_IN,
  SIGN_UP,
}

export enum Page {
  HOME,
  PREVIOUS,
}
const Start = () => {
  const [user, setUser] = useState<User | null>(null);
  const [userState, setUserState] = useState<UserState>(UserState.SIGN_IN);
  const [page, setPage] = useState<Page>(Page.HOME);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  return (
    <>
      <Header setPage={setPage} />
      {user ? (
        <>{page === Page.HOME ? <Home /> : <Previous />}</>
      ) : (
        <div>
          {userState === UserState.SIGN_IN ? (
            <SignIn setUserState={setUserState} />
          ) : (
            <SignUp setUserState={setUserState} />
          )}
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Link
              to="/privacy"
              style={{ color: "black", textDecoration: "none" }}
            >
              Privacy Policy
            </Link>
            <Link
              to="/delete"
              style={{ color: "black", textDecoration: "none" }}
            >
              Delete my data
            </Link>
          </Box>
        </div>
      )}
    </>
  );
};

export default Start;
