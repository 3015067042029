import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Start from "./pages/Start/Start";
import DeleteDataPage from "./pages/DeleteDataPage/DeleteDataPage";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Start />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="delete" element={<DeleteDataPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
