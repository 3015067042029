import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ArchiveAd from "../../components/ArchiveAd/ArchiveAd";

interface Alternatives {
  name: string;
  latin_name: string;
  certainty: number;
  wikipedia: string;
}

interface AiResponse {
  name: string;
  latin_name: string;
  description: string;
  certainty: number;
  wikipedia: string;
  alternatives: Alternatives[] | [];
}

interface IdentificationEntry {
  image: string;
  response: AiResponse;
}

const Previous = () => {
  const [entries, setEntries] = useState<IdentificationEntry[]>([]);

  // Load all previous identifications from localStorage on mount
  useEffect(() => {
    const existingData: IdentificationEntry[] =
      JSON.parse(localStorage.getItem("bugIdentifications") || "[]") || [];
    setEntries(existingData);
  }, []);

  // Handle removing an entry from localStorage
  const handleRemoveEntry = (indexToRemove: number) => {
    const updatedEntries = entries.filter(
      (_, index) => index !== indexToRemove
    );
    localStorage.setItem("bugIdentifications", JSON.stringify(updatedEntries));
    setEntries(updatedEntries); // Update state to reflect the removal
  };

  return (
    <Box p={2}>
      <Typography variant="h2" gutterBottom>
        Bug Archive
      </Typography>

      {entries.length === 0 ? (
        <Typography variant="body1">No previous entries found.</Typography>
      ) : (
        entries.map((entry, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box display="flex" alignItems="center" width="100%">
                <img
                  src={entry.image}
                  alt={`Identified bug ${entry.response.name}`}
                  style={{ width: "50px", height: "50px", marginRight: "16px" }}
                />
                <Box>
                  <Typography variant="h6">
                    {entry.response.name}
                    <br /> ({entry.response.latin_name})
                  </Typography>
                  <Link
                    href={entry.response.wikipedia}
                    target="_blank"
                    rel="noopener"
                  >
                    Wikipedia
                  </Link>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="subtitle1">Description:</Typography>
              <Typography variant="body2">
                {entry.response.description}
              </Typography>

              <Typography variant="subtitle1">
                Certainty: {entry.response.certainty}%
              </Typography>

              <Button
                variant="contained"
                color="error"
                onClick={() => handleRemoveEntry(index)}
              >
                Remove Entry
              </Button>
            </AccordionDetails>
          </Accordion>
        ))
      )}
      <ArchiveAd />
    </Box>
  );
};

export default Previous;
