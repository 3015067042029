import { Logout } from "@mui/icons-material";
import { Box } from "@mui/material";
import { Auth, signOut } from "firebase/auth";
import { auth } from "../../config/firebaseConfig";

function SignOut() {
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      // Successful signout, redirect or handle accordingly
    } catch (error) {
      // Handle error
    }
  };

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 3,
        cursor: "pointer",
        backgroundColor: "#f1e9d5",
        boxShadow: "0px 0px 2px 0px black",
      }}
      onClick={handleSignOut}
    >
      <Logout />
    </Box>
  );
}

export default SignOut;
