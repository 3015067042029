import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { getAuth, signInWithEmailAndPassword, deleteUser } from "firebase/auth";

// Make sure Firebase is initialized elsewhere in your app

const DeleteDataPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>(""); // Added password field for Firebase Auth
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const handleDeleteData = async () => {
    setLoading(true);
    setMessage("");

    try {
      const auth = getAuth();
      // Sign in the user to get the currentUser object
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Now, delete the user from Firebase Authentication
      await deleteUser(user);

      setMessage("Your data and account are now deleted.");
    } catch (error) {
      setMessage(
        "An error occurred. Please check your email and password or try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Delete My Data
      </Typography>
      <Typography variant="body1" gutterBottom>
        Enter your email address and password below, and we will delete your
        account and all associated data.
      </Typography>

      <Box sx={{ mt: 2 }}>
        <TextField
          label="Email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
        />
      </Box>

      <Box sx={{ mt: 2 }}>
        <TextField
          label="Password"
          fullWidth
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
        />
      </Box>

      <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteData}
            disabled={!email || !password}
          >
            Delete My Data
          </Button>
        )}
      </Box>

      {message && (
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          sx={{ mt: 4 }}
        >
          {message}
        </Typography>
      )}
    </Container>
  );
};

export default DeleteDataPage;
