import { useState } from "react";
import { auth } from "../../config/firebaseConfig"; // Import the auth object
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FirebaseError } from "firebase/app";
import { LoadingButton } from "@mui/lab";
import { UserState } from "../Start/Start";
import { parseError } from "./auth.util";

type Props = {
  setUserState: (state: UserState) => void;
};

function SignIn({ setUserState }: Props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSignIn = async () => {
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Successful signin, redirect or handle accordingly
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        setError(parseError(error));
      }
    }
    setIsLoading(false);
  };

  return (
    <Grid
      container
      direction={"column"}
      sx={{
        ml: "auto",
        mr: "auto",

        overflow: "hidden",
      }}
    >
      <Grid
        container
        direction={"column"}
        sx={{ ml: "auto", mr: "auto", p: "1rem" }}
        rowSpacing={4}
      >
        <Typography variant="h3">SIGN IN</Typography>
        <FormControl>
          <InputLabel htmlFor="my-input">Email address</InputLabel>
          <Input
            id="my-input"
            aria-describedby="my-helper-text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormHelperText id="my-helper-text">
            We'll never share your email.
          </FormHelperText>
        </FormControl>

        <FormControl>
          <InputLabel htmlFor="my-input">Password</InputLabel>
          <Input
            id="my-input"
            type="password"
            aria-describedby="my-helper-text"
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>

        <LoadingButton
          variant={"outlined"}
          onClick={handleSignIn}
          loading={isLoading}
          sx={{ p: 2, borderColor: "#aeb8a7", color: "#aeb8a7" }}
        >
          Sign In
        </LoadingButton>
        {error && (
          <p>
            <span style={{ color: "red" }}>{error}</span>
          </p>
        )}

        <LoadingButton
          variant={"outlined"}
          onClick={() => setUserState(UserState.SIGN_UP)}
          loading={isLoading}
          sx={{
            p: 2,
            backgroundColor: "#aeb8a7",
            color: "white",
            borderColor: "#aeb8a7",
          }}
        >
          Sign up
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

export default SignIn;
