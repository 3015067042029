// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBpNBI0QZbjAfB1NcDwZcAgZReO0RnIV8w",
  authDomain: "bugidentifaier.firebaseapp.com",
  projectId: "bugidentifaier",
  storageBucket: "bugidentifaier.appspot.com",
  messagingSenderId: "164047552813",
  appId: "1:164047552813:web:a41174859f8d7479f8d3a4",
  measurementId: "G-FWQGXQ4F74",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth }; // Export the auth object to use in your components
