import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Header from "../../components/Header/Header";

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Header />
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            Privacy Policy for Wild Snap: Bugs
          </Typography>
          <Typography variant="body1" gutterBottom>
            Effective Date: 2024-09-06
          </Typography>

          <Typography variant="body1" gutterBottom>
            At Wild Snap: Bugs, we respect your privacy and are committed to
            protecting your personal data. This Privacy Policy explains how we
            collect, use, and safeguard your information when you use our
            application "Wild Snap: Bugs" (the "App") available on Android and
            through our website.
          </Typography>

          <Typography variant="h5" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" gutterBottom>
            When you use Wild Snap: Bugs, we collect the following information:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                <strong>Email Address:</strong> Required for account creation
                and login purposes.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Password:</strong> Stored securely and used for
                authentication.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>UUID (Unique User Identifier):</strong> Generated upon
                account creation and used to uniquely identify your account.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Tokens:</strong> We store the number of tokens
                associated with your account, which represent how many
                identifications you can perform using the App.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Previous Searches:</strong> Search history (images
                you’ve uploaded for identification) is stored locally on your
                device and is not shared or transferred to our servers.
              </Typography>
            </li>
          </ul>

          <Typography variant="h5" gutterBottom>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1" gutterBottom>
            The information we collect is used solely for the following
            purposes:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                <strong>Account Management:</strong> Your email, password, and
                UUID are used to manage your account and provide access to the
                App.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Token Tracking:</strong> We use your UUID to track the
                number of tokens left in your account.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>App Functionality:</strong> The App allows you to upload
                or capture photos for insect identification. The images you
                upload are not stored or retained on our servers beyond the
                processing necessary for identification purposes.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Search History:</strong> Your previous search results
                are stored locally on your device to allow you to review them
                later. This data is not transferred or stored on our servers.
              </Typography>
            </li>
          </ul>

          <Typography variant="h5" gutterBottom>
            3. Data Storage and Security
          </Typography>
          <Typography variant="body1" gutterBottom>
            We take data security seriously. All personal data (email, password,
            UUID, and tokens) is securely stored and handled by Google Firebase.
            Firebase is compliant with industry-standard security measures,
            including encryption and secure data transmission.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Your password is stored securely using industry-standard encryption
            techniques and is never stored in plain text.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Your search history is stored{" "}
            <strong>locally on your device</strong> and is not accessible by us
            or shared with third parties.
          </Typography>

          <Typography variant="h5" gutterBottom>
            4. Data Sharing
          </Typography>
          <Typography variant="body1" gutterBottom>
            We do not sell, trade, or otherwise transfer your personal
            information to any third parties. We only use your data internally
            to ensure the proper functionality of the App.
          </Typography>

          <Typography variant="h5" gutterBottom>
            5. Your Rights
          </Typography>
          <Typography variant="body1" gutterBottom>
            As a user of Wild Snap: Bugs, you have certain rights concerning
            your personal data:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                <strong>Access:</strong> You can request access to the data we
                store about you.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Correction:</strong> You can request correction of
                inaccurate or incomplete information.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Deletion:</strong> You can request that we delete your
                account and associated data at any time by contacting us. Please
                note that your locally stored search history is managed by you
                and can be deleted from your device at any time.
              </Typography>
            </li>
          </ul>

          <Typography variant="h5" gutterBottom>
            6. Data Retention
          </Typography>
          <Typography variant="body1" gutterBottom>
            We will retain your personal information for as long as your account
            is active or as necessary to provide services. If you decide to
            delete your account, we will promptly remove all associated data
            from our systems, except as required by law or for legitimate
            business purposes. Search History stored on your device will remain
            unless you manually delete it.
          </Typography>

          <Typography variant="h5" gutterBottom>
            7. Changes to This Privacy Policy
          </Typography>
          <Typography variant="body1" gutterBottom>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with an updated revision date. If we
            make significant changes, we will notify you through the App or by
            email.
          </Typography>

          <Typography variant="h5" gutterBottom>
            8. Contact Us
          </Typography>
          <Typography variant="body1" gutterBottom>
            If you have any questions or concerns regarding this Privacy Policy
            or your personal data, please contact us at:
          </Typography>
          <Typography variant="body1" gutterBottom>
            Email: hejsan@operando.se
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
