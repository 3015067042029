import { FirebaseError } from "firebase/app";

export const parseError = (error: FirebaseError) => {
  switch (error.code) {
    case "auth/invalid-email":
      return "Invalid email address.";
    case "auth/user-disabled":
      return "User account disabled.";
    case "auth/user-not-found":
      return "User not found.";
    case "auth/wrong-password":
      return "Invalid password.";
    case "auth/configuration-not-found":
      return "Account not found. Sign up to create an account.";
    default:
      return "An error occurred.";
  }
};
