import {
  Box,
  Button,
  Link,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import axios from "axios";
import { CameraAlt, Close, Crop } from "@mui/icons-material";
import VerticalAd from "../../components/VerticalAd/VerticalAd";

enum Step {
  CAPTURE,
  CROP,
  SEND,
  RESPONSE,
}

interface Alternatives {
  name: string;
  latin_name: string;
  certainty: number;
  wikipedia: string;
}

interface AiResponse {
  name: string;
  latin_name: string;
  description: string;
  certainty: number;
  wikipedia: string;
  alternatives: Alternatives[] | [];
}

interface IdentificationEntry {
  image: string;
  response: AiResponse;
}

const Home = () => {
  const [uploadedImage, setImage] = useState<string | null>(null);
  const [step, setStep] = useState<Step>(Step.CAPTURE);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [response, setResponse] = useState<AiResponse | null>(null);
  const [croppedArea, setCropArea] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  // Set cropped area
  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCropArea(croppedAreaPixels);
  };

  // Handle image capture/upload
  const onCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImage(URL.createObjectURL(file));
      setStep(Step.CROP);
    }
  };

  // Handle cropping confirmation
  const handleCropConfirm = () => {
    setStep(Step.SEND);
  };

  // Save response and image to localStorage as an array
  const saveToLocalStorage = (image: string, res: AiResponse) => {
    // Retrieve existing data from localStorage
    const existingData = JSON.parse(
      localStorage.getItem("bugIdentifications") || "[]"
    );

    // Add the new identification to the array
    const updatedData: IdentificationEntry[] = [
      ...existingData,
      { image, response: res },
    ];

    // Save the updated array back to localStorage
    localStorage.setItem("bugIdentifications", JSON.stringify(updatedData));
  };

  // Send cropped image to API and save the response
  useEffect(() => {
    if (step === Step.SEND && canvasRef.current && croppedArea) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      if (imageRef.current && croppedArea) {
        const image = imageRef.current;

        context?.drawImage(
          image,
          croppedArea.x,
          croppedArea.y,
          croppedArea.width,
          croppedArea.height,
          0,
          0,
          canvas.width!,
          canvas.height!
        );

        // Send the cropped image blob to the API
        canvas.toBlob((blob) => {
          const formData = new FormData();
          formData.append("image", blob!, Date.now().toString() + ".jpeg");

          setIsLoading(true);
          axios
            .post("https://bug-api.operando.se", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.data) {
                setResponse(res.data);
                saveToLocalStorage(uploadedImage!, res.data); // Save both image and response
              }
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("API error:", error);
              setIsLoading(false);
            });
        });
      }
    }
  }, [step, croppedArea, uploadedImage]);

  return (
    <Box sx={{ ml: "auto", mr: "auto", textAlign: "center" }}>
      <Box sx={{ mb: 2, mt: 1 }}>
        <img
          ref={imageRef}
          src={uploadedImage || ""}
          alt="Bug"
          style={{ display: "none" }}
        />
        <Typography variant="h2">Identify a Bug</Typography>
        <Typography variant="h3">0/3 free tokens</Typography>
      </Box>
      {/* Image Capture/Upload */}
      {step === Step.CAPTURE && (
        <IconButton
          aria-label="upload"
          component="label"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "10rem",
            height: "10rem",
            ml: "auto",
            mr: "auto",
          }}
        >
          <input
            type="file"
            accept="image/*;capture=camera"
            onChange={onCapture}
            style={{ display: "none" }}
          />
          <CameraAlt sx={{ width: "5rem", height: "5rem" }} />
          Identify
        </IconButton>
      )}

      {/* Cropper Step */}
      {uploadedImage && step === Step.CROP && (
        <Box>
          <Box
            sx={{
              position: "fixed",
              left: "50%",
              bottom: "5%",
              transform: "translateX(-50%)",
              zIndex: 100,
            }}
          >
            <Button
              onClick={handleCropConfirm}
              variant="contained"
              sx={{
                backgroundColor: "#90EE90",
                color: "black",
              }}
            >
              <Crop sx={{ mr: 1 }} /> Confirm Crop
            </Button>
            <Button
              onClick={() => {
                setStep(Step.CAPTURE);
              }}
              variant="contained"
              sx={{
                backgroundColor: "#D2042D",
                color: "white",
                ml: 2,
              }}
            >
              <Close sx={{ mr: 1 }} /> Close
            </Button>
          </Box>

          <Box sx={{ width: 300, height: 300 }}>
            <Cropper
              image={uploadedImage}
              crop={crop}
              zoom={zoom}
              //cropSize={{ width: 300, height: 300 }}
              aspect={4 / 3}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </Box>
        </Box>
      )}

      {/* Canvas for cropping */}
      {step === Step.SEND && (
        <Box>
          <canvas
            width="300"
            height="300"
            ref={canvasRef}
            style={{ display: "none" }}
          ></canvas>
        </Box>
      )}

      {isLoading && <CircularProgress />}

      {/* Display the response and image */}
      {response && (
        <Box>
          <img
            src={uploadedImage || ""}
            alt="Bug"
            style={{ maxWidth: "100%", marginBottom: "16px" }}
          />
          <Typography variant="h3" gutterBottom>
            {response.name} ({response.latin_name})
          </Typography>
          <Typography variant="body1" gutterBottom>
            {response.description}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Certainty: {response.certainty}%
          </Typography>
          <Typography variant="body1" gutterBottom>
            More Information:{" "}
            <Link href={response.wikipedia} target="_blank" rel="noopener">
              Wikipedia
            </Link>
          </Typography>

          {/* Alternatives */}
          {response.alternatives && response.alternatives.length > 0 && (
            <Box mt={4}>
              <Typography variant="h5" gutterBottom>
                Alternative Identifications:
              </Typography>
              {response.alternatives.map((alternative, index) => (
                <Box key={index} mb={2}>
                  <Typography variant="h6">
                    {alternative.name} ({alternative.latin_name})
                  </Typography>
                  <Typography variant="subtitle1">
                    Certainty: {alternative.certainty}%
                  </Typography>
                  <Typography variant="body1">
                    More Information:{" "}
                    <Link
                      href={alternative.wikipedia}
                      target="_blank"
                      rel="noopener"
                    >
                      Wikipedia
                    </Link>
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
          <Button
            variant={"outlined"}
            onClick={() => {
              setStep(Step.CAPTURE);
              setResponse(null);
            }}
            sx={{ mb: 2 }}
          >
            Identify another bug
          </Button>
        </Box>
      )}
      <VerticalAd />
    </Box>
  );
};

export default Home;
