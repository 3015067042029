import React, { useEffect } from "react";

const ArchiveAd: React.FC = () => {
  useEffect(() => {
    // Function to push ads
    const pushAds = () => {
      try {
        if ((window as any).adsbygoogle) {
          (window as any).adsbygoogle.push({});
        }
      } catch (e) {
        console.error("Adsense error", e);
      }
    };

    // Load the AdSense script only once
    const scriptId = "adsbygoogle-fluid-js";
    if (!document.getElementById(scriptId)) {
      const adsScript = document.createElement("script");
      adsScript.async = true;
      adsScript.src =
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3672567103961829";
      adsScript.crossOrigin = "anonymous";
      adsScript.id = scriptId;
      document.body.appendChild(adsScript);
      adsScript.onload = pushAds;
    } else {
      pushAds();
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-format="fluid"
      data-ad-layout-key="-fb+5w+4e-db+86"
      data-ad-client="ca-pub-3672567103961829"
      data-ad-slot="7110059284"
    ></ins>
  );
};

export default ArchiveAd;
