import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Logo from "../../logo.png";
import { Inventory, Logout, ManageSearch, Search } from "@mui/icons-material";
import { Page } from "../../pages/Start/Start";
import SignOut from "../../pages/auth/SignOut";
type HeaderProps = {
  setPage?: (page: Page) => void;
};
const Header = ({ setPage }: HeaderProps) => {
  return (
    <Box
      sx={{
        backgroundColor: "#aeb8a7",
        mt: 0,
        minHeight: "5rem",
        borderBottom: "5px solid black",
      }}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"space-around"}
        alignItems={"center"}
        spacing={5}
        sx={{
          ml: "auto",
          mr: "auto",
        }}
      >
        <Grid
          sx={{
            width: "5rem",
            height: "5rem",
            borderRadius: "1rem",
            overflow: "hidden",
            m: 2,
          }}
        >
          <img
            src={Logo}
            alt="logo"
            style={{ width: "100%" }}
            onClick={() => (window.location.href = "/")}
          />
        </Grid>
        <Grid container spacing={2}>
          {setPage && (
            <>
              <Box
                sx={{
                  p: 2,
                  borderRadius: 3,
                  cursor: "pointer",
                  backgroundColor: "#f1e9d5",
                  boxShadow: "0px 0px 2px 0px black",
                }}
                onClick={() => setPage(Page.HOME)}
              >
                <Search />
              </Box>
              <Box
                sx={{
                  p: 2,
                  borderRadius: 3,
                  cursor: "pointer",
                  backgroundColor: "#f1e9d5",
                  boxShadow: "0px 0px 2px 0px black",
                }}
                onClick={() => setPage(Page.PREVIOUS)}
              >
                <Inventory />
              </Box>
              <SignOut />
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
